/**
 * Independent time operation tool to facilitate subsequent switch to dayjs
 */
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Shanghai");

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DATE_FORMAT = "YYYY-MM-DD";

export function formatToDateTime(
  date: dayjs.ConfigType,
  format = DATE_TIME_FORMAT
): string {
  return date ? dayjs(date).format(format) : "";
}

export function formatToDate(
  date: dayjs.ConfigType,
  format = DATE_FORMAT
): string {
  return date ? dayjs(date).format(format) : "";
}

export function formatToISO(date: dayjs.ConfigType): string {
  return date ? dayjs(date).toISOString() : "";
}

export function formatToUnixMilliseconds(date: dayjs.ConfigType): number {
  return dayjs(date).valueOf();
}

export function formatToDatePeriod(date: dayjs.ConfigType): string {
  let p;
  const d = dayjs(date);

  if (d.hour() < 12) {
    p = "上午";
  } else {
    p = "下午";
  }

  return `${dayjs(date).format("YYYY-MM-DD")} ${p}`;
}

export function formatToYearMonth(date: dayjs.ConfigType): string {
  return dayjs(date).format("YYYY-MM");
}

export const dateUtil = dayjs;
export { dayjs };
