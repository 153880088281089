<template>
  <div class="anticon" :class="getAppLogoClass" @click="goHome">
    <div
      class="truncate md:opacity-100"
      :class="getTitleClass"
      v-show="showTitle"
    >
      <div :class="$style['box']">
        <img :src="logo" :class="$style['img']" />
        <div>
          {{ title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, unref, useCssModule } from "vue";
import { useGlobSetting } from "/@/hooks/setting";
import { useGo } from "/@/hooks/web/usePage";
import { useMenuSetting } from "/@/hooks/setting/useMenuSetting";
import { useDesign } from "/@/hooks/web/useDesign";
import { PageEnum } from "/@/enums/pageEnum";
import { useUserStore } from "/@/store/modules/user";
import logo from "../../../assets/images/logo_short.png";

const props = defineProps({
  /**
   * The theme of the current parent component
   */
  theme: {
    type: String,
    validator: (v: string) => ["light", "dark"].includes(v),
  },
  /**
   * Whether to show title
   */
  showTitle: { type: Boolean, default: true },
  /**
   * The title is also displayed when the menu is collapsed
   */
  alwaysShowTitle: { type: Boolean },
});

const { prefixCls } = useDesign("app-logo");
const { getCollapsedShowTitle } = useMenuSetting();
const userStore = useUserStore();
const { title } = useGlobSetting();
const go = useGo();
const styles = useCssModule();

const getAppLogoClass = computed(() => [
  prefixCls,
  props.theme,
  { "collapsed-show-title": unref(getCollapsedShowTitle) },
  styles["wrap"],
]);

const getTitleClass = computed(() => [
  `${prefixCls}__title`,
  {
    "xs:opacity-0": !props.alwaysShowTitle,
  },
]);

function goHome() {
  go(userStore.getUserInfo.homePath || PageEnum.BASE_HOME);
}
</script>
<style lang="less" scoped>
@prefix-cls: ~"@{namespace}-app-logo";

.@{prefix-cls} {
  display: flex;
  align-items: center;
  padding-left: 7px;
  cursor: pointer;
  transition: all 0.2s ease;

  &.light {
    border-bottom: 1px solid @border-color-base;
  }

  &.collapsed-show-title {
    padding-left: 20px;
  }

  &.light &__title {
    color: @primary-color;
  }

  &.dark &__title {
    color: @white;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    transition: all 0.5s;
    line-height: normal;
  }
}
</style>
<style lang="less" module>
.wrap {
  height: unset !important;
  cursor: pointer;
  padding: 0 0 24px !important;
  // margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

.box {
  box-sizing: border-box;
  height: 122px;
  padding: 32px 12px;
  background-color: #208bd7;
  line-height: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 500;
  border-radius: 0 0 2px 2px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .img {
    width: auto;
    height: 36px;
    object-fit: contain;
  }
}
</style>
