import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { createApp } from "vue";
import { VueQueryPlugin } from "@tanstack/vue-query";
import App from "./App.vue";
import { initAppConfigStore } from "/@/logics/initAppConfig";
// import { setupErrorHandle } from '/@/logics/error-handle';
import { router, setupRouter } from "/@/router";
import { setupRouterGuard } from "/@/router/guard";
import { setupStore } from "/@/store";
import { setupGlobDirectives } from "/@/directives";
import { setupI18n } from "/@/locales/setupI18n";
import { registerGlobComp } from "/@/components/registerGlobComp";
import "./validate";
import "ant-design-vue/dist/antd.less";
import "@peacebird/ant-design-vue-plus/dist/style.css";
import "/@/design/index.less";
import "./styles/tailwind.css";
import "./styles/vben.less";
import * as Sentry from "@sentry/vue";

async function bootstrap() {
  const app = createApp(App);

  if (import.meta.env.PROD) {
    // Sentry.init({
    //   app,
    //   dsn: import.meta.env.VITE_SENTRY_DSN,
    //   integrations: [
    //     new Sentry.BrowserTracing({
    //       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    //     }),
    //     // TODO: 性能问题，暂时关闭
    //     // new Sentry.Replay(),
    //   ],
    //   tracesSampleRate: 0.5,
    //   environment: import.meta.env.MODE,
    //   debug: globalThis.SENTRY_DEBUG === true,
    //   replaysSessionSampleRate: 0.1,
    //   replaysOnErrorSampleRate: 1.0,
    // });
  }

  // Configure store
  setupStore(app);

  // Initialize internal system configuration
  initAppConfigStore();

  // Register global components
  registerGlobComp(app);

  // Multilingual configuration
  // Asynchronous case: language files may be obtained from the server side
  await setupI18n(app);

  // Configure routing
  setupRouter(app);

  // router-guard
  setupRouterGuard(router);

  // Register global directive
  setupGlobDirectives(app);

  // Configure global error handling
  // setupErrorHandle(app);

  // https://next.router.vuejs.org/api/#isready
  // await router.isReady();

  // dayjs 中文
  dayjs.locale("zh-cn");

  // 添加 @tanstack/vue-query
  app.use(VueQueryPlugin, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          staleTime: 0,
          cacheTime: 0,
          retry: false,
          refetchInterval: false,
          refetchOnReconnect: false,
          refetchOnWindowFocus: false,
        },
      },
    },
  });

  app.mount("#app");
}

bootstrap();
