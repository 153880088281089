export enum PageEnum {
  // basic login path
  BASE_LOGIN = "/platform/login",
  // basic home path
  BASE_HOME = "/dashboard",
  // error page path
  ERROR_PAGE = "/exception",
  // error log page path
  ERROR_LOG_PAGE = "/error-log/list",
}
