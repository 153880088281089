import { defHttp } from "/@/utils/http/axios";
import { getMenuListResultModel } from "./model/menuModel";

enum Api {
  GetMenuList = "/getRouters",
}

/**
 * @description: Get user menu based on id
 */

export const getMenuList = async () => {
  const resData = await defHttp.get<getMenuListResultModel>({
    url: Api.GetMenuList,
  });

  const recurse = (ar) => {
    return ar.map((x) => {
      const { pathName, ...rest } = x;

      return {
        ...rest,
        name: pathName || x.name,
        children: x.children ? recurse(x.children) : [],
      };
    });
  };

  return recurse(resData);
};
