import { defineRule } from "vee-validate";
import {
  required,
  email,
  max,
  length,
  confirmed,
  min_value,
  max_value,
} from "@vee-validate/rules";

defineRule("required", (v) => {
  return typeof v === "boolean" ? true : required(v) ? true : "必填";
});
defineRule("email", (...arg) => (email(...arg) ? true : "无效的邮箱地址"));
defineRule("max", (...arg) => (max(...arg) ? true : "超过最大值"));
defineRule("length", (...arg) => (length(...arg) ? true : "长度不符合要求"));
defineRule("confirmed", (...arg) =>
  confirmed(...arg) ? true : "两次输入不一致"
);
defineRule("phoneNumber", (value) => {
  if (!/^[1][0-9]{10}$/.test(value)) {
    return "无效手机号";
  }

  return true;
});
defineRule("min_value", min_value);
defineRule("max_value", max_value);
