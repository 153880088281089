import { defHttp } from "/@/utils/http/axios";
import {
  LoginParams,
  LoginResultModel,
  WxLoginParams,
} from "./model/userModel";

import { ErrorMessageMode } from "/#/axios";

enum Api {
  Login = "/login",
  LoginForMerchant = "/auth/loginPc",
  Logout = "/logout",
  GetUserInfo = "/getInfo",
  GetRouters = "/getRouters",
  GetPermCode = "/auth/getPermCode",
  wxLogin = "/wxqr/login",
}

/**s
 * @description: user login api
 */
export function loginApi(
  params: LoginParams,
  mode: ErrorMessageMode = "modal"
) {
  return defHttp.post<LoginResultModel>(
    {
      url: Api.Login,
      params,
    },
    {
      errorMessageMode: mode,
    }
  );
}
export function loginForMerchantApi(
  params: LoginParams,
  mode: ErrorMessageMode = "modal"
) {
  return defHttp.post<LoginResultModel>(
    {
      url: Api.LoginForMerchant,
      params,
    },
    {
      errorMessageMode: mode,
    }
  );
}

/**
 * @description: getUserInfo
 */
export async function getUserInfo() {
  const resData = await defHttp.get(
    { url: Api.GetUserInfo },
    { errorMessageMode: "none" }
  );

  return {
    userId: resData.user.userId,
    username: resData.user.userName,
    realName: resData.user.nickName,
    avatar: resData.avatar,
    roles: [],
    ...resData,
  };
}

export function getRouters() {
  return defHttp.get({ url: Api.GetRouters }, { errorMessageMode: "none" });
}

export function getPermCode() {
  return defHttp.get<string[]>({ url: Api.GetPermCode });
}

export function doLogout() {
  return defHttp.get({ url: Api.Logout });
}
export function wxqrLoginApi(params: WxLoginParams) {
  return defHttp.post({ url: Api.wxLogin, params });
}

export function fsqrLoginApi(data: any) {
  return defHttp.post({ url: "/fs/login", data });
}

export function fsWebLoginApi(data: any) {
  return defHttp.post({ url: "/h5/fsweb/login", data });
}
